@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap";
* {
  box-sizing: border-box;
}

:root {
  --font-montserrat: "Montserrat", sans-serif;
  --font-lato: "Lato", sans-serif;
  --font-garamond: "EB Garamond", serif;
  --col-white: #fff;
  --col-offwhite: #f5f5f5;
  --col-black: #000;
  --col-offblack: #1d1d1d;
  --col-orange: #eb772a;
  --col-dark-orange: #cc5000;
  font-family: var(--font-lato);
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.horizontal-rule {
  background-color: #0003;
  width: 100%;
  max-width: 1500px;
  height: 1px;
  margin: 0 auto;
}

.no-transition {
  -o-transition: none !important;
  transition: none !important;
}

.italic {
  font-style: italic;
}

.heading-1 {
  font-size: 56px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  text-align: center;
}

.heading-1 span {
  color: var(--col-dark-orange);
}

@media screen and (width <= 600px) {
  .heading-1 {
    font-size: 48px;
  }
}

.header__navbar {
  z-index: 5;
  color: var(--col-white);
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 32px;
  transition: background .3s, padding .3s;
  display: flex;
  position: absolute;
}

.header__navbar.dark {
  color: var(--col-black);
}

.header {
  justify-content: space-between;
  width: fit-content;
  height: 100%;
  display: flex;
  position: relative;
}

.navbar__logo {
  height: 64px;
}

.navbar__logo img {
  width: auto;
  height: 100%;
}

.navbar__links {
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.navbar__link {
  display: flex;
}

.navbar__link a {
  font-family: var(--font-montserrat);
  text-align: center;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.navbar__link a:after {
  content: "";
  background-color: var(--col-dark-orange);
  z-index: -5;
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar__link.active a:before {
  content: "";
  z-index: -5;
  background-color: #cc520080;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar__link a:hover:after {
  content: "";
  z-index: -5;
  height: 2px;
  margin: 0 auto;
  animation-name: borderMorph;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  position: absolute;
}

.navbar__toggle {
  cursor: pointer;
  background: none;
  border: none;
  transition: color .3s;
  display: none;
}

.navbar__toggle:hover {
  color: var(--col-dark-orange);
}

.navbar__logo.black {
  visibility: hidden;
  display: none;
}

.header__navbar.dark .navbar__logo {
  display: none;
}

.header__navbar.dark .navbar__logo.black {
  display: block;
}

@media screen and (width <= 900px) {
  .header__navbar {
    background-color: var(--col-white);
    color: #000;
    padding: 16px;
    position: fixed;
    box-shadow: 0 5px 15px #0000001a;
  }

  .navbar__logo {
    visibility: hidden;
    display: none;
  }

  .navbar__logo.black {
    visibility: visible;
    height: 50px;
    display: block;
  }

  .header__navbar.dark .navbar__logo {
    visibility: visible;
    display: block;
  }

  .header__navbar.dark .navbar__logo.black {
    visibility: hidden;
    display: none;
  }

  .header {
    width: 100%;
  }

  .navbar__toggle {
    display: block;
  }

  .navbar__links-container {
    width: 100%;
    height: 0;
    transition: height .3s;
    overflow: hidden;
  }

  .show-links {
    height: 188px;
  }

  .navbar__links {
    flex-direction: column;
    align-items: end;
    gap: 4px;
    margin: 0;
    padding: 16px 0;
  }
}

@keyframes borderMorph {
  0% {
    width: 0%;
    height: 0%;
  }

  50% {
    width: 1%;
    height: 100%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

.header-banner {
  z-index: -2;
  width: 100%;
  height: 256px;
  transition: height .3s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.header-banner.bg-dark {
  background-color: var(--col-offblack);
}

.header-banner.tall {
  height: 396px;
}

.banner__container {
  background: linear-gradient(0deg, #0000, #0003 70%);
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.banner__img {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 2s;
}

@media screen and (width <= 900px) {
  .header-banner {
    height: 96px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.full-hero {
  flex-direction: column;
  width: 100%;
  height: round(up, 100vh, 100px);
  display: flex;
  position: relative;
  overflow: hidden;
}

.full-hero__img {
  object-fit: cover;
  z-index: -2;
  width: 100%;
  position: absolute;
}

@media screen and (width <= 1350px) {
  .full-hero__img {
    object-fit: cover;
    z-index: -2;
    height: 100%;
  }
}

.full-hero__content {
  color: var(--col-white);
  background: linear-gradient(0deg, #0000 0%, #0006 43%, #0000 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 128px 8px 8px;
  display: flex;
}

.full-hero__content h1 {
  text-align: center;
  font-size: 56px;
  font-weight: 400;
  font-family: var(--font-garamond);
  width: 100%;
  position: relative;
}

.full-hero__content h3 {
  letter-spacing: 2px;
  color: #fff9;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  font-family: var(--font-montserrat);
  width: 100%;
  margin: 0;
}

.full-hero__content a {
  transition: letter-spacing .3s, color .3s;
}

.full-hero__content a:hover {
  letter-spacing: 4px;
  color: var(--col-white);
}

.backdrop-section {
  flex-direction: column;
  width: 100%;
  height: round(up, 100vh, 200px);
  display: flex;
  position: relative;
  overflow: hidden;
}

.backdrop-section.short {
  height: round(up, 50vh, 200px);
}

.backdrop-section__bg {
  object-fit: cover;
  z-index: -2;
  width: 100%;
  display: flex;
  position: absolute;
}

@media screen and (width <= 1350px) {
  .backdrop-section__bg {
    object-fit: cover;
    z-index: -2;
    height: 100%;
  }
}

.backdrop-section__content {
  color: var(--col-white);
  background: #0000004d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px;
  display: flex;
}

.section__small-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  font-size: 15px;
}

.section__title {
  font-size: 56px;
  font-weight: 400;
  font-family: var(--font-garamond);
  text-align: center;
}

.section__subtitle {
  font-size: 28px;
  font-weight: 400;
  font-family: var(--font-garamond);
  text-align: center;
  margin-top: 0;
}

.section {
  background-color: var(--col-offwhite);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.section.inverse {
  background-color: var(--col-offblack);
  color: var(--col-white);
}

.section__content {
  text-align: center;
  padding: 175px 50px;
}

.section__content .constrained {
  max-width: 900px;
  margin: 0 auto;
}

.section__content h2 {
  color: var(--col-black);
  font-size: 33px;
  font-family: var(--font-garamond);
  margin-top: 0;
}

.section__content p {
  color: #00000080;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
}

.section.inverse h2 {
  color: var(--col-white);
}

.section.inverse p {
  color: #ffffff80;
}

.section__button {
  border: 2px solid var(--col-offblack);
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 11px;
  font-family: var(--font-lato);
  width: fit-content;
  margin: 34px auto 0;
  padding: 13px 26px;
  transition: color .3s, background .3s;
  display: block;
}

.inverse .section__button {
  border: 2px solid var(--col-offwhite);
}

.section__button.bigger {
  padding: 21px 34px;
}

.section__button:hover {
  color: var(--col-white);
  background-color: var(--col-offblack);
}

.inverse .section__button:hover {
  color: var(--col-black);
  background-color: var(--col-offwhite);
}

@media screen and (width <= 960px) {
  .section__content {
    padding: 120px 48px;
  }
}

@media screen and (width <= 640px) {
  .section__content {
    padding: 60px 20px;
  }
}

.services-container {
  grid-template-columns: calc(33.3333% - 50px) calc(33.3333% - 50px) calc(33.3333% - 50px);
  gap: 48px 75px;
  margin-top: 64px;
  margin-bottom: .83em;
  display: grid;
}

.service {
  max-width: 475px;
  margin: 0 auto;
}

.service__icon {
  width: 32px;
  height: 32px;
}

.service h3.service__title {
  font-family: var(--font-montserrat);
  margin: 16px 0 8px;
}

.service p.service__text {
  color: #fff9;
  font-size: 15px;
  font-family: var(--font-lato);
  margin: 0;
}

@media screen and (width <= 1300px) {
  .services-container {
    grid-template-columns: calc(50% - 37.5px) calc(50% - 37.5px);
    gap: 36px 75px;
    display: grid;
  }
}

@media screen and (width <= 900px) {
  .services-container {
    grid-template-columns: 100%;
    gap: 36px 75px;
    display: grid;
  }
}

.video-bg-section {
  background-color: #ffffffb3;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-bg-section__video {
  object-fit: cover;
  z-index: -2;
  width: 100%;
  position: absolute;
}

.video-bg-section__content {
  text-align: center;
  padding: 175px 50px;
}

@media screen and (width <= 960px) {
  .video-bg-section__content {
    padding: 120px 48px;
  }
}

@media screen and (width <= 750px) {
  .video-bg-section__video {
    object-fit: cover;
    z-index: -2;
    height: 100%;
  }
}

h2.section__title-thin {
  font-weight: 400;
}

.page-section {
  color: var(--col-black);
  background-color: var(--col-offwhite);
  padding: 24px 20px;
}

.page-section.grey {
  color: var(--col-white);
  background-color: #00000080;
  position: relative;
}

.page-section.dark {
  color: var(--col-white);
  background-color: var(--col-offblack);
}

.page-section h1 {
  text-align: center;
  font-size: 56px;
  font-weight: 400;
  font-family: var(--font-garamond);
  width: 100%;
  position: relative;
}

.page-section p {
  color: #00000080;
}

.page-section.grey p {
  color: #fffc;
}

.page-section.dark p {
  color: #ffffff80;
}

.page-section > p {
  text-align: center;
  color: #000000b3;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 18px;
}

.page-section.grey > p {
  color: #ffffffe6;
}

.page-section.dark > p {
  color: #fffc;
}

article {
  max-width: 1200px;
  margin: 32px auto;
}

article.wide {
  max-width: 1600px;
}

article.narrow {
  max-width: 1000px;
}

.offerings-grid {
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  justify-content: center;
  column-gap: 40px;
  display: grid;
}

.offerings-grid p {
  margin: 8px 0;
}

@media screen and (width <= 600px) {
  .offerings-grid {
    grid-template-columns: 100%;
  }
}

.article__image-and-text {
  align-items: center;
  gap: 16px;
  display: flex;
}

.article__image-and-text .image {
  width: 50%;
  max-width: 50%;
  height: 100%;
}

.article__image-and-text .text {
  flex-direction: column;
  display: flex;
}

.article__image-and-text .text h2 {
  margin-left: 0;
  margin-right: 0;
}

article h2 {
  font-size: 36px;
  font-family: var(--font-garamond);
  width: fit-content;
  margin: 16px auto;
  font-weight: 500;
  position: relative;
}

article h2:after {
  content: "";
  background-color: var(--col-dark-orange);
  width: 90%;
  height: 1px;
  position: absolute;
  bottom: 4px;
  left: 5%;
}

.grey article h2:after, .dark article h2:after, .inverse article h2:after {
  background-color: #ff822f;
}

@media screen and (width <= 900px) {
  article {
    margin-bottom: 32px;
  }

  .article__image-and-text {
    flex-direction: column;
  }

  .article__image-and-text .image {
    width: 100%;
    max-width: 100%;
  }
}

.cards-carousel {
  max-width: 1200px;
  margin: 16px auto;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cards-carousel .card-container {
  flex-basis: 33.3333%;
  justify-content: center;
  align-items: center;
  width: 33.3333%;
  min-width: 33.3333%;
  transition: transform .5s;
  display: flex;
}

@media screen and (width <= 1000px) {
  .cards-carousel .card-container {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }
}

.carousel__prev, .carousel__next {
  cursor: pointer;
  z-index: 4;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  position: absolute;
  top: calc(50% - 16px);
}

.carousel__prev {
  left: 0;
}

.carousel__next {
  right: 0;
}

.card {
  background-color: var(--col-white);
  z-index: -1;
  border: 1px solid #0000001a;
  width: fit-content;
  height: 352px;
  display: flex;
}

.card__sidebar:before {
  content: "";
  background-color: var(--col-dark-orange);
  width: 64px;
  height: 32px;
  display: inline-block;
}

.card__sidebar {
  writing-mode: vertical-lr;
  align-items: center;
  width: 64px;
  height: 100%;
  display: flex;
}

.card__sidebar a {
  font-size: 20px;
  font-family: var(--font-montserrat);
  text-align: center;
  height: 100%;
}

.card__details {
  flex-direction: column;
  width: 256px;
  height: 100%;
  display: flex;
  position: relative;
}

.card__logo-container {
  width: 100%;
  min-height: 96px;
  display: flex;
}

.card__logo {
  object-fit: contain;
  width: 100%;
  height: auto;
  max-height: 128px;
  padding: 0 32px;
}

.card__title {
  text-align: center;
  padding: 0 16px;
}

.card__subtitle {
  color: #000000b3;
  text-align: center;
  letter-spacing: -.6px;
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
}

.card__footer {
  background-color: var(--col-offblack);
  color: var(--col-white);
  flex-direction: column;
  justify-content: end;
  align-items: start;
  width: 90%;
  height: 100px;
  padding: 8px;
  transition: transform .3s;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.card__info {
  font-size: 12px;
  font-family: var(--font-lato);
  letter-spacing: -.1px;
  font-weight: 300;
}

.associate__desc {
  background-color: var(--col-white);
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  min-height: 200px;
  margin: 0 auto 32px;
  padding: 0 16px;
  display: flex;
}

.associate__desc h2 {
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  font-family: var(--font-garamond);
  width: 100%;
}

.project-grid__container {
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
  gap: 4px;
  max-width: 1600px;
  margin: 64px auto;
  display: grid;
}

.project-grid__item {
  perspective: 550px;
  aspect-ratio: 4 / 3;
  background-color: var(--col-white);
  justify-content: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.project-grid__img {
  object-fit: cover;
  width: 100%;
}

.project-grid__info {
  pointer-events: none;
  color: var(--col-white);
  background-color: #0009;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate3d(1, 0, 0, 90deg);
}

.project-grid__title {
  font-size: 36px;
  font-weight: 300;
  font-family: var(--font-garamond);
  text-align: center;
  margin: 8px 0;
}

.project-grid__subtitle {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-lato);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fffc;
  margin: 0;
}

.project-grid__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project-grid__link:focus {
  border: 2px solid #000;
}

@media screen and (width <= 1100px) {
  .project-grid__container {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (width <= 700px) {
  .project-grid__container {
    grid-template-columns: 100%;
    margin: 32px auto;
  }

  .project-grid__item {
    perspective: 700px;
  }
}

.in-top .project-grid__info {
  transform-origin: 50% 0;
  animation: .3s forwards in-top;
}

.in-right .project-grid__info {
  transform-origin: 100% 0;
  animation: .3s forwards in-right;
}

.in-bottom .project-grid__info {
  transform-origin: 50% 100%;
  animation: .3s forwards in-bottom;
}

.in-left .project-grid__info {
  transform-origin: 0 0;
  animation: .3s forwards in-left;
}

.out-top .project-grid__info {
  transform-origin: 50% 0;
  animation: .3s forwards out-top;
}

.out-right .project-grid__info {
  transform-origin: 100%;
  animation: .3s forwards out-right;
}

.out-bottom .project-grid__info {
  transform-origin: 50% 100%;
  animation: .3s forwards out-bottom;
}

.out-left .project-grid__info {
  transform-origin: 0 0;
  animation: .3s forwards out-left;
}

@keyframes in-top {
  from {
    transform: rotate3d(-1, 0, 0, 117deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0);
  }
}

@keyframes in-right {
  from {
    transform: rotate3d(0, -1, 0, 117deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0);
  }
}

@keyframes in-bottom {
  from {
    transform: rotate3d(1, 0, 0, 117deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0);
  }
}

@keyframes in-left {
  from {
    transform: rotate3d(0, 1, 0, 117deg);
  }

  to {
    transform: rotate3d(0, 0, 0, 0);
  }
}

@keyframes out-top {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }

  to {
    transform: rotate3d(-1, 0, 0, 117deg);
  }
}

@keyframes out-right {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }

  to {
    transform: rotate3d(0, -1, 0, 117deg);
  }
}

@keyframes out-bottom {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }

  to {
    transform: rotate3d(1, 0, 0, 117deg);
  }
}

@keyframes out-left {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }

  to {
    transform: rotate3d(0, 1, 0, 117deg);
  }
}

.contact-card {
  background-color: var(--col-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  min-height: 300px;
  margin: 32px auto 16px;
  padding: 8px 16px;
  transition: box-shadow .3s;
  display: flex;
  box-shadow: 2px 2px 4px 2px #0000001a;
}

.contact-card__icon {
  width: auto;
  height: 38px;
}

.contact-card__title {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  font-family: var(--font-garamond);
  width: 100%;
  margin: 16px 0;
}

.contact-card__text {
  text-align: center;
  margin: 8px 0;
}

.contact-card__link {
  border: 2px solid var(--col-offblack);
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 11px;
  font-family: var(--font-lato);
  width: fit-content;
  margin-top: 32px;
  padding: 13px 26px;
  transition: color .3s, background .3s;
  display: block;
}

.contact-card__link:hover {
  color: var(--col-white);
  background-color: var(--col-offblack);
}

.footer__slant {
  background: var(--col-offwhite);
  margin-bottom: -1px;
  display: flex;
}

.footer__slant.bg-dark {
  background: var(--col-offblack);
}

.footer__slant.bg-none {
  background: none;
}

.footer__slant path:first-child {
  stroke: #424242;
  fill: #424242;
}

.footer__slant path:nth-child(2) {
  stroke: #1d1d1d;
  fill: #1d1d1d;
}

.footer__slant.bg-dark path:nth-child(2) {
  stroke: #292929;
  fill: #292929;
}

.footer__slant path:nth-child(3) {
  stroke: #050505;
  fill: #050505;
}

.footer {
  background-color: #050505;
  padding: 96px 32px;
  position: relative;
}

.footer__inner {
  color: var(--col-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
}

.footer__logo {
  width: auto;
  height: 36px;
}

.footer__links {
  color: var(--col-offwhite);
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.footer__links a {
  color: #ffffff80;
  font-size: 14px;
  font-family: var(--font-montserrat);
  padding: 12px 16px;
  transition: color .3s;
}

.footer__links a:hover {
  color: var(--col-offwhite);
  padding: 12px 16px;
}

.footer__small {
  font-size: 12px;
  font-family: var(--font-lato);
  text-align: center;
}

.footer__small a {
  color: #ffffff80;
  transition: color .3s;
}

.footer__small a:hover {
  color: var(--col-offwhite);
}
/*# sourceMappingURL=index.b1d2bbdd.css.map */
