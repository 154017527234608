@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
* {
  box-sizing: border-box;
}

:root {
  --font-montserrat: "Montserrat", sans-serif;
  --font-lato: "Lato", sans-serif;
  --font-garamond: "EB Garamond", serif;

  --col-white: #fff;
  --col-offwhite: #f5f5f5;
  --col-black: #000;
  --col-offblack: #1d1d1d;
  --col-orange: #eb772a;
  --col-dark-orange: #cc5000;

  font-family: var(--font-lato);
}

body {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
.horizontal-rule {
  height: 1px;
  width: 100%;
  max-width: 1500px;
  background-color: rgba(0,0,0,0.2);
  margin: 0 auto;
}
.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.italic {
  font-style: italic;
}
.heading-1 {
  font-size: 56px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  text-align: center;
}
.heading-1 span {
  color: var(--col-dark-orange);
}
@media screen and (max-width: 600px) {
  .heading-1 {
    font-size: 48px;
  }
}

/* Navbar */
.header__navbar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px;
  transition: 0.3s all, background 0s, color 0s;
  z-index: 5;
  transition: background 0.3s, padding 0.3s;
  color: var(--col-white);
}
.header__navbar.dark {
  color: var(--col-black);
}
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: fit-content;
}
.navbar__logo {
  height: 64px;
}
.navbar__logo img {
  height: 100%;
  width: auto;
}
.navbar__links {
  display: flex;
  align-items: center;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.navbar__link {
  display: flex;
}
.navbar__link a {
  position: relative;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-weight: 600;
  padding: 12px 16px;
  text-align: center;
}
.navbar__link a::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: var(--col-dark-orange);
  z-index: -5;
}
.navbar__link.active a::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #cc520080;
  margin: 0 auto;
  z-index: -5;
}
.navbar__link a:hover::after {
  position: absolute;
  content: '';
  height: 2px;
  margin: 0 auto;
  z-index: -5;
  animation-name: borderMorph;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}
.navbar__toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  transition: color 0.3s;
}
.navbar__toggle:hover {
  color: var(--col-dark-orange);
}
.navbar__logo.black {
  display: none;
  visibility: hidden;
}
.header__navbar.dark .navbar__logo {
  display: none;
}
.header__navbar.dark .navbar__logo.black {
  display: block;
}
@media screen and (max-width: 900px) {
  .header__navbar {
    position: fixed;
    background-color: var(--col-white);
    color: #000;
    padding: 16px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  .navbar__logo {
    display: none;
    visibility: hidden;
  }
  .navbar__logo.black {
    display: block;
    visibility: visible;
    height: 50px;
  }
  .header__navbar.dark .navbar__logo {
    display: block;
    visibility: visible;
  }
  .header__navbar.dark .navbar__logo.black {
    display: none;
    visibility: hidden;
  }
  .header {
    width: 100%;
  }
  .navbar__toggle {
    display: block;
  }
  .navbar__links-container {
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 0.3s;
  }
  .show-links {
    height: 188px;
  }
  .navbar__links {
    margin: 0;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0;
    align-items: end;
  }
}
@keyframes borderMorph {
  0%  {
    width: 0%;
    height: 0%;
  }
  50% {
    width: 1%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
/* Banner */
.header-banner {
  position: relative;
  display: flex;
  width: 100%;
  height: 256px;
  overflow: hidden;
  transition: height 0.3s;
  z-index: -2;
}
.header-banner.bg-dark {
  background-color: var(--col-offblack);
}
.header-banner.tall {
  height: 396px;
}
.banner__container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(0deg, rgba(0,0,0,0.0), rgba(0,0,0,0.2) 70%);
}
.banner__img {
  object-fit: cover;
  width: 100%;
  z-index: -1;
  animation-name: fadeIn;
  animation-duration: 2s;
}
@media screen and (max-width: 900px) {
  .header-banner {
    height: 96px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Hero */
.full-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: round(up, 100vh, 100px);
  overflow: hidden;
}
.full-hero__img {
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
@media screen and (max-width: 1350px) {
  .full-hero__img {
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
}
.full-hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  padding-top: 128px;
  height: 100%;
  color: var(--col-white);
  background: rgba(255,255,255, 0);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 43%, rgba(0,0,0,0) 100%);
}
.full-hero__content h1 {
  position: relative;
  width: 100%;
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  font-family: var(--font-garamond);
}
.full-hero__content h3 {
  width: 100%;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 700;
  color: rgba(255,255,255,0.6);
  text-align: center;
  font-family: var(--font-montserrat);
  margin: 0;
}
.full-hero__content a {
  transition: letter-spacing 0.3s, color 0.3s;
}
.full-hero__content a:hover {
  letter-spacing: 4px;
  color: var(--col-white);
}

/* Backdropped Section */
.backdrop-section {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: round(up, 100vh, 200px);
  overflow: hidden;
}
.backdrop-section.short {
  height: round(up, 50vh, 200px);
}
.backdrop-section__bg {
  display: flex;
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
@media screen and (max-width: 1350px) {
  .backdrop-section__bg {
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
}
.backdrop-section__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100%;
  color: var(--col-white);
  background: rgba(0,0,0,0.3);
}
.section__small-title {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
}
.section__title {
  font-size: 56px;
  font-weight: 400;
  font-family: var(--font-garamond);
  text-align: center;
}
.section__subtitle {
  margin-top: 0;
  font-size: 28px;
  font-weight: 400;
  font-family: var(--font-garamond);
  text-align: center;
}

/* Section */
.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--col-offwhite);
}
.section.inverse {
  background-color: var(--col-offblack);
  color: var(--col-white);
}
.section__content {
  padding: 175px 50px;
  text-align: center;
}
.section__content .constrained {
  margin: 0 auto;
  max-width: 900px;
}
.section__content h2 {
  margin-top: 0;
  font-size: 33px;
  color: var(--col-black);
  font-family: var(--font-garamond);
}
.section__content p {
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  color: rgba(0,0,0,0.5);
}
.section.inverse h2 {
  color: var(--col-white);
}
.section.inverse p {
  color: rgba(255,255,255,0.5);
}
.section__button {
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-top: 34px;
  border: 2px solid var(--col-offblack);
  padding: 13px 26px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  font-family: var(--font-lato);
  transition: color 0.3s, background 0.3s;
}
.inverse .section__button {
  border: 2px solid var(--col-offwhite);
}
.section__button.bigger {
  padding: 21px 34px;
}
.section__button:hover {
  color: var(--col-white);
  background-color: var(--col-offblack);
}
.inverse .section__button:hover {
  color: var(--col-black);
  background-color: var(--col-offwhite);
}

@media screen and (max-width: 960px) {
  .section__content {
    padding: 120px 48px;
  }
}
@media screen and (max-width: 640px) {
  .section__content {
    padding: 60px 20px;
  }
}

/* Services */
.services-container {
  display: grid;
  grid-template-columns: calc(100%/3 - 50px) calc(100%/3 - 50px) calc(100%/3 - 50px);
  column-gap: 75px;
  row-gap: 48px;
  margin-top: 64px;
  margin-bottom: 0.83em;
}
.service {
  max-width: 475px;
  margin: 0 auto;
}
.service__icon {
  width: 32px;
  height: 32px;
}
.service  h3.service__title{
  font-family: var(--font-montserrat);
  margin: 16px 0 8px 0;
}
.service p.service__text {
  font-size: 15px;
  color: rgba(255,255,255,0.6);
  font-family: var(--font-lato);
  margin: 0;
}
@media screen and (max-width: 1300px) {
  .services-container {
    display: grid;
    grid-template-columns: calc(100%/2 - 37.5px) calc(100%/2 - 37.5px);
    column-gap: 75px;
    row-gap: 36px;
  }
}
@media screen and (max-width: 900px) {
  .services-container {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 75px;
    row-gap: 36px;
  }
}

/* Video Underlay Section */
.video-bg-section {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255,255,255,0.7);
}
.video-bg-section__video {
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
.video-bg-section__content {
  padding: 175px 50px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .video-bg-section__content {
    padding: 120px 48px;
  }
}
@media screen and (max-width: 750px) {
  .video-bg-section__video {
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
}
h2.section__title-thin {
  font-weight: 400;
}

/* Page Section */
.page-section {
  padding: 24px 20px;
  color: var(--col-black);
  background-color: var(--col-offwhite);
}
.page-section.grey {
  position: relative;
  color: var(--col-white);
  background-color: rgba(0,0,0,0.5);
}
.page-section.dark {
  color: var(--col-white);
  background-color: var(--col-offblack);
}
.page-section h1 {
  position: relative;
  width: 100%;
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  font-family: var(--font-garamond);
}
.page-section p {
  color: rgba(0,0,0,0.5);
}
.page-section.grey p {
  color: rgba(255,255,255,0.8);
}
.page-section.dark p {
  color: rgba(255,255,255,0.5);
}

.page-section > p {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: rgba(0,0,0,0.7);
  font-size: 18px;
}
.page-section.grey > p {
  color: rgba(255,255,255,0.9);
}
.page-section.dark > p {
  color: rgba(255,255,255,0.8);
}

/* Articles */
article {
  margin: 32px auto;
  max-width: 1200px;
}
article.wide {
  max-width: 1600px;
}
article.narrow {
  max-width: 1000px;
}

.offerings-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  column-gap: 40px;
}
.offerings-grid p {
  margin: 8px 0;
}
@media screen and (max-width: 600px) {
  .offerings-grid {
    grid-template-columns: 100%;
  }
}

.article__image-and-text {
  display: flex;
  align-items: center;
  gap: 16px;
}
.article__image-and-text .image {
  width: 50%;
  max-width: 50%;
  height: 100%;
}
.article__image-and-text .text {
  display: flex;
  flex-direction: column;
}
.article__image-and-text .text h2 {
  margin-left: 0;
  margin-right: 0;
} 

article h2 {
  position: relative;
  font-size: 36px;
  font-family: var(--font-garamond);
  font-weight: 500;
  width: fit-content;
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
}
article h2::after {
  content: "";
  height: 1px;
  left: 5%;
  position: absolute;
  bottom: 4px;
  width: 90%;
  background-color: var(--col-dark-orange);
}
.grey article h2::after,
.dark article h2::after,
.inverse article h2::after {
  background-color: rgb(255, 130, 47);
}

@media screen and (max-width: 900px) {
  article {
    margin-bottom: 32px;
  }
  .article__image-and-text {
    flex-direction: column;
  }
  .article__image-and-text .image {
    width: 100%;
    max-width: 100%;
  }
}

/* Associate Carousel */
.cards-carousel {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 0 16px;
  margin: 16px auto;
  max-width: 1200px;
}
.cards-carousel .card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: calc(100%/3);
  width: calc(100%/3);
  min-width: calc(100%/3);
  transition: transform 0.5s;
}
@media screen and (max-width: 1000px) {
  .cards-carousel .card-container {
    flex-basis: calc(100%);
    width: calc(100%);
    min-width: calc(100%);
  }
}
.carousel__prev,
.carousel__next {
  position: absolute;
  top: calc(50% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 4;
}
.carousel__prev {
  left: 0;
}
.carousel__next {
  right: 0;
}

/* Associate Cards */
.card {
  display: flex;
  height: 352px;
  width: fit-content;
  background-color: var(--col-white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.card__sidebar::before {
  content: '';
  display: inline-block;
  width: 64px;
  height: 32px;
  background-color: var(--col-dark-orange);
}
.card__sidebar {
  display: flex;
  align-items: center;
  width: 64px;
  height: 100%;
  writing-mode: vertical-lr;
}
.card__sidebar a {
  font-size: 20px;
  font-family: var(--font-montserrat);
  height: 100%;
  text-align: center;
}
.card__details {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 256px;
}
.card__logo-container {
  display: flex;
  width: 100%;
  min-height: 96px;
}
.card__logo {
  padding: 0 32px;
  object-fit: contain;
  max-height: 128px;
  height: auto;
  width: 100%;
}
.card__title {
  padding: 0 16px;
  text-align: center;
}
.card__subtitle {
  font-size: 14px;
  color: rgba(0,0,0,0.7);
  padding: 0 16px;
  text-align: center;
  margin: 0;
  letter-spacing: -0.6px;
}
.card__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8px;
  justify-content: end;
  height: 100px;
  width: 90%;
  background-color: var(--col-offblack);
  color: var(--col-white);
  transition: transform 0.3s;
}
.card__info {
  font-size: 12px;
  font-family: var(--font-lato);
  font-weight: 300;
  letter-spacing: -0.1px;
}

.associate__desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  margin-bottom: 32px;
  padding: 0 16px;
  background-color: var(--col-white);
}
.associate__desc h2 {
  width: 100%;
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  font-family: var(--font-garamond);
}

/* Project Grid */
.project-grid__container {
  display: grid;
  grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
  max-width: 1600px;
  margin: 64px auto;
  gap: 4px;
}
.project-grid__item {
  display: flex;
  justify-content: center;
  width: 100%;
  perspective: 550px;
  aspect-ratio: 4/3;
  overflow: hidden;
  background-color: var(--col-white);
}
.project-grid__img {
  object-fit: cover;
  width: 100%;
}
.project-grid__info {
  transform: rotate3d(1,0,0, 90deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: rgba(0,0,0,0.6);
  color: var(--col-white);
}
.project-grid__title {
  margin: 0;
  margin: 8px 0;
  font-size: 36px;
  font-weight: 300;
  font-family: var(--font-garamond);
  text-align: center;
}
.project-grid__subtitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-lato);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255,255,255,0.8);
}
.project-grid__link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.project-grid__link:focus {
  border: 2px solid black;
}

@media screen and (max-width: 1100px) {
  .project-grid__container {
    grid-template-columns: calc(100%/2) calc(100%/2);
  }
}
@media screen and (max-width: 700px) {
  .project-grid__container {
    margin: 32px auto;
    grid-template-columns: 100%;
  }
  .project-grid__item {
    perspective: 700px;
  }
}

.in-top .project-grid__info {
  transform-origin: 50% 0%;
  animation: in-top 300ms ease 0ms 1 forwards;
}
.in-right .project-grid__info {
  transform-origin: 100% 0%;
  animation: in-right 300ms ease 0ms 1 forwards;
}
.in-bottom .project-grid__info {
  transform-origin: 50% 100%;
  animation: in-bottom 300ms ease 0ms 1 forwards;
}
.in-left .project-grid__info {
  transform-origin: 0% 0%;
  animation: in-left 300ms ease 0ms 1 forwards;
}

.out-top .project-grid__info {
  transform-origin: 50% 0%;
  animation: out-top 300ms ease 0ms 1 forwards;
}
.out-right .project-grid__info {
  transform-origin: 100% 50%;
  animation: out-right 300ms ease 0ms 1 forwards;
}
.out-bottom .project-grid__info {
  transform-origin: 50% 100%;
  animation: out-bottom 300ms ease 0ms 1 forwards;
}
.out-left .project-grid__info {
  transform-origin: 0% 0%;
  animation: out-left 300ms ease 0ms 1 forwards;
}

@keyframes in-top {
  from {transform: rotate3d(-1,0,0, 117deg)}
  to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-right {
  from {transform: rotate3d(0,-1,0, 117deg)}
  to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-bottom {
  from {transform: rotate3d(1,0,0, 117deg)}
  to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-left {
  from {transform: rotate3d(0,1,0, 117deg)}
  to   {transform: rotate3d(0,0,0, 0deg)}}

@keyframes out-top {
  from {transform: rotate3d(0,0,0, 0deg)}
  to   {transform: rotate3d(-1,0,0, 117deg)}}
@keyframes out-right {
  from {transform: rotate3d(0,0,0, 0deg)}
  to   {transform: rotate3d(0,-1,0, 117deg)}}
@keyframes out-bottom {
  from {transform: rotate3d(0,0,0, 0deg)}
  to   {transform: rotate3d(1,0,0, 117deg)}}
@keyframes out-left {
  from {transform: rotate3d(0,0,0, 0deg)}
  to   {transform: rotate3d(0,1,0, 117deg)}}

/* Contact */
.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 300px;
  max-width: 400px;
  margin: 32px auto 16px auto;
  background-color: var(--col-white);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}
.contact-card__icon {
  width: auto;
  height: 38px;
}
.contact-card__title {
  width: 100%;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  margin: 16px 0;
  font-family: var(--font-garamond);
}
.contact-card__text {
  margin: 8px 0;
  text-align: center;
}
.contact-card__link {
  margin-top: 32px;
  display: block;
  width: fit-content;
  border: 2px solid var(--col-offblack);
  padding: 13px 26px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  font-family: var(--font-lato);
  transition: color 0.3s, background 0.3s;
}
.contact-card__link:hover {
  color: var(--col-white);
  background-color: var(--col-offblack);
}

/* Footer */
.footer__slant {
  display: flex;
  background: var(--col-offwhite);
  margin-bottom: -1px;
}
.footer__slant.bg-dark {
  background: var(--col-offblack);
}
.footer__slant.bg-none {
  background: transparent;
}
.footer__slant path:nth-child(1) {
  stroke: #424242;
  fill: #424242;
}
.footer__slant path:nth-child(2) {
  stroke: #1d1d1d;
  fill: #1d1d1d;
}
.footer__slant.bg-dark path:nth-child(2) {
  stroke: #292929;
  fill: #292929;
}
.footer__slant path:nth-child(3) {
  stroke: rgb(5,5,5);
  fill: rgb(5,5,5);
}
.footer {
  position: relative;
  padding: 96px 32px;
  background-color: rgb(5,5,5);
}
.footer__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--col-white);
  width: 100%;
  gap: 16px;
}
.footer__logo {
  height: 36px;
  width: auto;
}
.footer__links {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  color: var(--col-offwhite);
}
.footer__links a {
  color: rgba(255,255,255,0.5);
  font-size: 14px;
  font-family: var(--font-montserrat);
  padding: 12px 16px;
  transition: color 0.3s;
}
.footer__links a:hover {
  color: var(--col-offwhite);
  padding: 12px 16px;
}
.footer__small {
  font-size: 12px;
  font-family: var(--font-lato);
  text-align: center;
}
.footer__small a {
  color: rgba(255,255,255,0.5);
  transition: 0.3s color;
}
.footer__small a:hover {
  color: var(--col-offwhite);
}